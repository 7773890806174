import { useCallback, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import { useLocalStorageValue } from '@react-hookz/web';
import { merge } from 'lodash';
import { DarkTheme } from './darkTheme';
import { LightTheme } from './lightTheme';

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

export type UseGlobalTheme = {
  /** The theme to pass to the top level `ThemeProvider` */
  theme: Theme;
  /** The current theme mode */
  mode: ThemeMode;
  /** Toggle between light and dark themes */
  toggleTheme: () => void;
  /** Set the theme mode */
  setTheme: (mode: ThemeMode) => void;
};

export type UseGlobalThemeProps = {
  /** Make all MUI animations complete instantly - useful for testing */
  disableAnimations?: boolean;
};

export const STORAGE_KEY = 'Shuttlerock:ThemeMode';

const dispatch = (curr: ThemeMode, next: ThemeMode) => {
  const storageArea = localStorage;
  const oldValue = JSON.stringify(curr);
  const newValue = JSON.stringify(next);
  const event = new StorageEvent('storage', {
    key: 'Shuttlerock:ThemeMode',
    oldValue,
    newValue,
    storageArea,
  });
  window.dispatchEvent(event);
};

/**
 * `useGlobalTheme` returns which theme to pass to the top level `ThemeProvider`, the
 * current `ThemeMode` and functions to change the active theme.
 */
export const useGlobalTheme = ({
  disableAnimations,
}: UseGlobalThemeProps = {}): UseGlobalTheme => {
  const [mode, setMode] = useLocalStorageValue(STORAGE_KEY, ThemeMode.Light);

  const toggleTheme = useCallback(() => {
    setMode((curr) => {
      const next = curr === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light;
      dispatch(curr, next);
      return next;
    });
  }, [setMode]);

  const setTheme = useCallback(
    (mode: ThemeMode) =>
      setMode((curr) => {
        if (mode !== curr) dispatch(curr, mode);
        return mode;
      }),
    [setMode],
  );

  const theme = useMemo(() => {
    const activeTheme = mode === ThemeMode.Dark ? DarkTheme : LightTheme;

    return disableAnimations
      ? merge({}, activeTheme, {
          transitions: {
            create: () => 'none',
            duration: Object.fromEntries(
              Object.entries(activeTheme.transitions.duration || {}).map(
                ([key]) => [key, 0],
              ),
            ),
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                '*, *::before, *::after': {
                  transition: 'none !important',
                  animation: 'none !important',
                },
              },
            },
          },
        })
      : activeTheme;
  }, [disableAnimations, mode]);

  return useMemo(
    () => ({ theme, mode, toggleTheme, setTheme }),
    [theme, mode, toggleTheme, setTheme],
  );
};
