/* eslint-disable */
import { isValidElement } from 'react';
import { CellProps, HeaderProps, Renderer } from 'react-table';

export const renderCell = <D extends object = Record<string, unknown>>(
  base?: Renderer<HeaderProps<D> | CellProps<D>>,
  props?: HeaderProps<D> | CellProps<D>,
) => {
  if (
    !base ||
    typeof base === 'string' ||
    typeof base === 'number' ||
    isValidElement(base)
  ) {
    return base;
  }

  // @ts-ignore
  return typeof base === 'function' ? base(props) : base;
};
