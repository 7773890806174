// Because react-table -_-
import { SxProps, Theme } from '@mui/material/styles';
/* eslint-disable*/
/* @ts-nocheck */
import { Hooks } from 'react-table';
import merge from 'lodash/merge';

const processHeaders = (props: any, { column }: any) => {
  const sx = merge({}, column?.sx || {}, column?.headerSx || {});
  return Object.keys(sx).length ? { ...props, sx } : props;
};
const processCells = (props: any, { cell }: any) => {
  const sx = merge({}, cell?.column?.sx || {}, cell?.column?.cellSx || {});
  return Object.keys(sx).length ? { ...props, sx } : props;
};

export type ColumnSx = {
  sx?: SxProps<Theme>;
  cellSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
};

/**
 * This plugin allows using `sx` props in column definitions. Supported properties are:
 *
 * `sx` - this will be returned by both `column.getHeaderProps` and `column.getCellProps`
 *
 * `headerSx` - this will be returned by `column.getHeaderProps`. Duplicate keys in `headerSx` will take precedence over `sx`
 *
 * `cellSx` - this will be returned by `column.getCellProps`. Duplicate keys in `cellSx` will take precedence over `sx`
 */
export const useColumnSx = <D extends object = Record<string, unknown>>(
  hooks: Hooks<D>,
): void => {
  hooks.getHeaderProps.push(processHeaders);
  hooks.getCellProps.push(processCells);
};

useColumnSx.pluginName = 'useColumnSx';
