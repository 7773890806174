// Because react-table -_-
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TableSortLabel } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import type { Hooks, TableOptions } from 'react-table';
import {
  actions,
  ActionType,
  ColumnInstance,
  HeaderPropGetter,
  HeaderProps,
  TableState,
  useSortBy,
} from 'react-table';
import { renderCell } from '../utils';

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UseSortingState = {
  sort: { key: string; direction: SortDirection }[];
};

actions.sortingChanged = 'sortingChanged';

const processColumns = <D extends object = Record<string, unknown>>(
  columns: ColumnInstance<D>[],
) =>
  columns.map((column) => ({
    ...column,
    Header: (props: HeaderProps<D>) => {
      // @ts-expect-error Typings are crap
      if (column.disableSortBy) return column.Header;

      const col = props.column as ColumnInstance<D> & {
        isSorted: boolean;
        isSortedDesc: boolean;
      };
      const direction = col.isSortedDesc ? 'desc' : 'asc';

      return (
        <TableSortLabel active={col.isSorted} direction={direction}>
          {renderCell(column.Header, props)}
        </TableSortLabel>
      );
    },
  }));

const processHeaders: HeaderPropGetter<any> = (props, { instance, column }) => {
  const { dispatch } = instance;
  // @ts-ignore
  const sortProps = column.getSortByToggleProps();

  // @ts-ignore
  const onClick = column.canSort
    ? (e: MouseEventHandler<HTMLTableDataCellElement>) => {
        sortProps.onClick(e);
        dispatch({ type: actions.sortingChanged });
      }
    : undefined;

  return { ...props, onClick };
};

const reducer = (state: TableState, action: ActionType) => {
  if (action.type === actions.init) {
    return {
      ...state,
      sort: [],
    };
  }

  if (action.type === actions.sortingChanged) {
    return {
      ...state,
      // @ts-ignore
      sort: state.sortBy.map((x) => ({
        key: x.id,
        direction: x.desc ? SortDirection.Desc : SortDirection.Asc,
      })),
    };
  }

  return undefined;
};

const useOptions = (options: TableOptions<any>) => {
  // @ts-ignore - assume server-side sorting by default
  const manualSortBy = options.manualSortBy !== false;
  return { ...options, manualSortBy };
};

export const useSorting = <D extends object = Record<string, unknown>>(
  hooks: Hooks<D>,
): void => {
  useSortBy(hooks);
  hooks.getHeaderProps.push(processHeaders);
  // @ts-ignore
  hooks.visibleColumns.push(processColumns);
  hooks.stateReducers.push(reducer);
  // @ts-ignore
  hooks.useOptions.push(useOptions);
};

// This MUST be named `useSortBy` to prevent an error when
// `react-table` validates the supplied plugins vs their `pluginName`
useSorting.pluginName = 'useSortBy';
