// Because react-table -_-
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { IconButton, styled } from '@mui/material';
import React, { KeyboardEvent } from 'react';
import { RowPropGetter, useExpanded } from 'react-table';
import type {
  ColumnInstance,
  Hooks,
  CellProps as TableCellProps,
  UseTableCellProps,
} from 'react-table';
import { FaIcon } from '../../Icon/FaIcon';
import { renderCell } from '../utils';

export interface UseExpansionOptions {
  /**
   * flag to allow expanding by clicking anywhere on the row
   * @default true
   */
  expandOnRowClick?: boolean;
}

type CellProps<D extends object = Record<string, unknown>> = TableCellProps<D> &
  UseTableCellProps<Record<string, unknown>, unknown> &
  UseExpansionOptions;
type ExpandableWrapperProps = { depth: number; canExpand: boolean };

const ExpandableWrapper = styled('div', {
  shouldForwardProp: (prop) => !['depth', 'canExpand'].includes(prop as string),
})<ExpandableWrapperProps>`
  display: flex;
  align-items: center;
  padding-left: calc(
    ${(props) => props.theme.spacing(3)} * ${(props) => props.depth || 0}${(props) => (!props.canExpand ? ' + 30px' : '')}
  );

  button.expander {
    font-size: ${(props) => props.theme.typography.caption.fontSize};
    margin-right: ${(props) => props.theme.spacing(1)};
    svg.expanded {
      transform: rotate(90deg);
    }
  }
`;

const handleKeyDown =
  (toggleFn: (expand?: boolean) => void) => (e: KeyboardEvent) => {
    // Older browsers (IE / Firefox < v37) return "Spacebar" instead of " "
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      toggleFn();
    }

    if (
      e.key === 'ArrowRight' ||
      e.key === 'ArrowDown' ||
      // Older browsers (IE / Firefox < v37) omit the "Arrow"
      e.key === 'Right' ||
      e.key === 'Down'
    ) {
      toggleFn(true);
    }

    if (
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowUp' ||
      // Older browsers (IE / Firefox < v37) omit the "Arrow"
      e.key === 'Left' ||
      e.key === 'Up'
    ) {
      toggleFn(false);
    }
  };

const processColumns = <D extends object = Record<string, unknown>>(
  columns: ColumnInstance<D>[],
) => {
  const firstColumn = columns[0];

  if (!firstColumn) {
    return columns;
  }

  const augmented = {
    ...firstColumn,
    Cell: (props: CellProps<D>) => {
      const { row, value } = props;
      // @ts-ignore
      const { onClick: onButtonClick } = row.getToggleRowExpandedProps();
      // @ts-ignore
      const { onClick: onRowClick } = row.getRowProps();
      const onClick = !onRowClick ? onButtonClick : undefined;

      return (
        // @ts-ignore
        <ExpandableWrapper depth={row.depth} canExpand={row.canExpand}>
          {/* @ts-ignore */}
          {row.canExpand ? (
            <IconButton
              className="expander"
              aria-label="expand row"
              onClick={onClick}
              tabIndex={onRowClick ? -1 : undefined}
            >
              <FaIcon
                icon={faChevronRight}
                // @ts-ignore
                className={row.isExpanded ? 'expanded' : ''}
              />
            </IconButton>
          ) : null}
          {/* @ts-ignore */}
          {renderCell(firstColumn.Cell, props) || value}
        </ExpandableWrapper>
      );
    },
  };

  return [augmented, ...columns.slice(1)];
};

const processRows: RowPropGetter<any> = (props, meta) => {
  const { row } = meta;
  const { expandOnRowClick } = meta.instance as UseExpansionOptions;
  const addOnClick =
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    (expandOnRowClick || expandOnRowClick == undefined) && row.canExpand;

  return addOnClick
    ? {
        ...props,
        tabIndex: 0,
        hover: true,
        sx: { cursor: 'pointer' },
        // @ts-ignore
        onClick: () => row.toggleRowExpanded(),
        // @ts-ignore
        onKeyDown: handleKeyDown(row.toggleRowExpanded),
      }
    : props;
};

export const useExpansion = <D extends object = Record<string, unknown>>(
  hooks: Hooks<D>,
): void => {
  useExpanded(hooks);
  hooks.getRowProps.push(processRows);
  // @ts-ignore
  hooks.visibleColumns.push(processColumns);
};

// This MUST be named `useExpanded` to prevent an error when
// `react-table` validates the supplied plugins vs their `pluginName`
useExpansion.pluginName = 'useExpanded';
